<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
      :style="{
        backgroundImage: `url(${backgroundImage})`,
      }"
    >
      <!-- begin:: Content -->
      <div class="card px-5 m-16 py-5 d-flex flex-column justify-content-md-center" style="background-color: rgba(255, 255, 255, 0.6); border-radius: 10px">
        <div class="card-body">
          <h1 class="error-title text-stroke text-primary">404</h1>
          <p class="display-4 font-weight-boldest text-dark-75 mb-12">
            {{ $t("ERROR.404_TITLE") }}
          </p>
          <p class="font-size-h2 font-weight-boldest text-dark-75">
            {{ $t("ERROR.404_SUBTITLE") }}
          </p>
          <p class="font-size-h4 line-height-md">
            {{ $t("ERROR.404_TEXT") }}
            <br />
            {{ $t("ERROR.404_TEXT2") }}
            <br /><br />
            <small class="font-weight-bold">{{ $t("ERROR.404_TEXT3") }}</small>
            <br /><br />
          </p>
        </div>
        <div class="card-footer bg-transparent">
          <slot name="foot">
            <router-link class="btn btn-success font-weight-bolder font-size-h6 py-4 my-3" :to="{ name: 'dashboard' }">
              {{ $t("GENERAL.HOME") }}
            </router-link>
          </slot>
        </div>
      </div>

      <!-- end:: Content -->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/error/error-3.scss";
</style>

<script>
export default {
  name: "Error-404",
  mounted() {},
  computed: {
    backgroundImage() {
      return this.$laUtils.backgroundImage("media/error/bg3.jpg");
    },
  },
};
</script>
